<template>
    <v-expansion-panel>
        <template v-if="!addCustomer">
            <v-expansion-panel-header class="mt-2">
                Añadir nuevo cliente
                <template v-slot:actions>
                    <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                        <v-icon color="primary">mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                        <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                        Añadir nuevo cliente
                        <v-icon>mdi-message-question-outline</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <manage-customers @reloaderchild="reload" :key="reloadMangerCustomers" :win="win"></manage-customers>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-expansion-panel-content>
        </template>
        <customer-data v-else></customer-data>
    </v-expansion-panel>
</template>

<script>
    import CustomerData from "../tabs/CustomerData";
    import ManageCustomers from "@/views/customers/tabs/ManageCustomers";
    //import {mapState} from 'vuex';

    export default {
        name: "AddCustomer",
        components: {ManageCustomers, CustomerData},
        props: ['win'],
        data() {
            return {
                panel: 0,
                tab: null,
                addCustomer: false,
                reloadMangerCustomers: 0,
                requiredRule: [
                    value => !!value || 'Required.',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+/.test(v) || 'E-mail must be valid',
                ],
            }
        },
        computed:{
            /*...mapState('customerData', {
                tab: 'tab-1'
            }),*/
        },
        methods: {
            reload() {
                this.reloadMangerCustomers++;
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
            },
        },

    }
</script>

<style scoped>

</style>